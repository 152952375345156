<template>
    <div id="editBuildingPage" class="baseTemplate">
        <!-- begin:: mobile menu -->
        <app-mobilemenu></app-mobilemenu>
        <!-- end:: mobile menu -->

        <div class="kt-grid kt-grid--hor kt-grid--root">
            <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                <!-- begin:: home menu -->
                <app-siteconfigurationmenu activeItem="editBuilding"></app-siteconfigurationmenu>
                <!-- end:: home menu -->

                <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper">
                    <!-- begin:: header -->
                    <app-header></app-header>
                    <!-- end:: header -->

                    <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                        <!-- begin:: Content Head -->
                        <div class="kt-subheader kt-grid__item">
                            <app-sitetitle subHeaderDescKey="b&f_editBuildingSubTitle"></app-sitetitle>
                        </div>
                        <!-- end:: Content Head -->

                        <!-- begin:: Content -->
                        <div class="kt-content kt-grid__item kt-grid__item--fluid">
                            <!-- begin:: Content -->

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="kt-portlet">
                                        <div class="kt-portlet__head">
                                            <div class="kt-portlet__head-label">
                                                <span class="kt-portlet__head-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                            <rect x="0" y="0" width="24" height="24" />
                                                            <path
                                                                d="M13.5,21 L13.5,18 C13.5,17.4477153 13.0522847,17 12.5,17 L11.5,17 C10.9477153,17 10.5,17.4477153 10.5,18 L10.5,21 L5,21 L5,4 C5,2.8954305 5.8954305,2 7,2 L17,2 C18.1045695,2 19,2.8954305 19,4 L19,21 L13.5,21 Z M9,4 C8.44771525,4 8,4.44771525 8,5 L8,6 C8,6.55228475 8.44771525,7 9,7 L10,7 C10.5522847,7 11,6.55228475 11,6 L11,5 C11,4.44771525 10.5522847,4 10,4 L9,4 Z M14,4 C13.4477153,4 13,4.44771525 13,5 L13,6 C13,6.55228475 13.4477153,7 14,7 L15,7 C15.5522847,7 16,6.55228475 16,6 L16,5 C16,4.44771525 15.5522847,4 15,4 L14,4 Z M9,8 C8.44771525,8 8,8.44771525 8,9 L8,10 C8,10.5522847 8.44771525,11 9,11 L10,11 C10.5522847,11 11,10.5522847 11,10 L11,9 C11,8.44771525 10.5522847,8 10,8 L9,8 Z M9,12 C8.44771525,12 8,12.4477153 8,13 L8,14 C8,14.5522847 8.44771525,15 9,15 L10,15 C10.5522847,15 11,14.5522847 11,14 L11,13 C11,12.4477153 10.5522847,12 10,12 L9,12 Z M14,12 C13.4477153,12 13,12.4477153 13,13 L13,14 C13,14.5522847 13.4477153,15 14,15 L15,15 C15.5522847,15 16,14.5522847 16,14 L16,13 C16,12.4477153 15.5522847,12 15,12 L14,12 Z"
                                                                fill="#000000"
                                                            />
                                                            <rect fill="#FFFFFF" x="13" y="8" width="3" height="3" rx="1" />
                                                            <path d="M4,21 L20,21 C20.5522847,21 21,21.4477153 21,22 L21,22.4 C21,22.7313708 20.7313708,23 20.4,23 L3.6,23 C3.26862915,23 3,22.7313708 3,22.4 L3,22 C3,21.4477153 3.44771525,21 4,21 Z" id="Rectangle-2" fill="#000000" opacity="0.3" />
                                                        </g>
                                                    </svg>
                                                </span>
                                                <h3 class="kt-portlet__head-title">
                                                    {{ $t("b&f_editBuilding") }}
                                                </h3>
                                            </div>
                                        </div>

                                        <!--begin::Form-->
                                        <form class="kt-form" novalidate="novalidate" @submit.prevent>
                                            <div class="kt-portlet__body">
                                                <div class="form-group validated">
                                                    <label for="editBuildingForm_nameInput">{{ $t("b&f_buildingName") }} *</label>
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"><i class="la la-pencil"></i></span>
                                                        </div>
                                                        <input v-model="name" @input="$v.name.$touch()" @keyup="onChangeForm" type="text" class="form-control" id="editBuildingForm_nameInput" :placeholder="$t('b&f_buildingNameInputInfo')" />
                                                        <div v-if="!$v.name.required" class="invalid-feedback">
                                                            {{ $t("error_fieldIsRequired") }}
                                                        </div>
                                                        <div v-else-if="!$v.name.minLen" class="invalid-feedback">
                                                            {{ $t("error_minLengthMsg", [$v.name.$params.minLen.min]) }}
                                                        </div>
                                                    </div>
                                                    <span class="form-text text-muted"> {{ $t("b&f_buildingNameDetailInfo") }}</span>
                                                </div>
                                                <div class="form-group form-group-last validated">
                                                    <label for="editBuildingForm_descriptionInput">{{ $t("b&f_buildingDescription") }}</label>
                                                    <textarea id="editBuildingDescriptionTextArea" class="form-control" rows="3" v-model="description" @keyup="onChangeForm"></textarea>
                                                    <span class="form-text text-muted"> {{ $t("b&f_buildingDescriptionDetailInfo") }}</span>
                                                </div>
                                            </div>
                                            <div class="kt-portlet__foot">
                                                <div class="kt-form__actions kt-form__actions--right">
                                                    <button id="editBuildingUpdateButton" @click="onUpdateButton" type="button" class="btn btn-brand kt-margin-r-5" :disabled="$v.$invalid || !isFormDataChanged">
                                                        <i class="la la-save"></i>
                                                        {{ $t("common_saveChanges") }}
                                                    </button>
                                                    <button id="editBuildingCancelButton" @click="onCancelButton" type="button" class="btn btn-secondary">{{ $t("common_cancel") }}</button>
                                                </div>
                                            </div>
                                        </form>
                                        <!--end::Form-->
                                    </div>
                                </div>
                            </div>

                            <!-- end:: Content -->
                        </div>
                        <!-- end:: Content -->
                    </div>

                    <!-- begin:: Footer -->
                    <app-footer></app-footer>
                    <!-- end:: Footer -->
                    <!-- begin:: Modal to confirm leave page -->
                    <app-confirmleavepagemodal ref="confirmleavepagemodalref"></app-confirmleavepagemodal>
                    <!-- end::Modal -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { required, minLength } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";
import SiteTitle from "./../site/widgets/sitetitle.vue";
import commonVueHelper from "../../helpers/commonVueHelper";

export default {
    data() {
        return {
            name: "",
            description: "",
            siteId: this.$route.params.siteId,
            buildingId: this.$route.params.buildingId,
            isFormDataChanged: false
        };
    },
    created: function() {
        console.log("Component(editBuilding)::created() - called");
        const payload = {
            siteId: this.siteId,
            buildingId: this.buildingId
        };
        this.getBuildingById(payload);
    },
    mounted: function() {
        console.log("Component(editBuilding)::mounted() - Init metronic layout");
        KTLayout.init();
    },
    destroyed: function() {
        console.log("Component(editBuilding)::destroyed() - called");
        this.resetBuildingsState();
    },
    validations: {
        // -- validations -- List of controls to apply to validate a form.
        // --
        name: {
            required,
            minLen: minLength(2)
        }
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
        currentBuilding: function(building) {
            console.log("Component(editBuilding)::watch currentBuilding - called");
            if (building) {
                this.name = building.name;
                this.description = building.description;
            }
        }
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters(["currentSite", "currentBuilding"])
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapActions(["getBuildingById", "updateBuilding", "resetBuildingsState"]),

        // Function called when user click on the "Cancel" button
        onCancelButton() {
            console.log("Component(editBuilding)::onCancelButton() - called");
            this.$router.push({ name: "buildings", params: { fromAction: "cancelButton" } });
        },

        onUpdateButton() {
            console.log("Component(editBuilding)::onUpdateButton() - called");
            const data = {
                siteId: this.siteId,
                buildingId: this.buildingId,
                name: this.name,
                description: this.description
            };
            this.updateBuilding(data);
        },

        onChangeForm() {
            this.isFormDataChanged = true;
        }
    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
        "app-sitetitle": SiteTitle
    },

    beforeRouteLeave(to, from, next) {
        // -- Called when the route that renders this component is about to be navigated away from.
        // --
        commonVueHelper.displayConfirmModalOnLeavePage(this, "EditBuilding", "update", this.isFormDataChanged, to, from, next);
    }
};
</script>

<style scoped>
.baseTemplate {
    display: contents;
}
</style>
